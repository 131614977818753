@charset "UTF-8";

/* Slider */

.slick-loading .slick-list {
  background: #ffffff url("..//./ajax-loader.gif") center center no-repeat;
}

/* Icons */

@font-face {
  font-weight: normal;
  font-family: "slick";
  font-style: normal;
  src: url("..//./fonts/slick.eot");
  src: url("..//./fonts/slick.eot?#iefix") format("embedded-opentype"), url("..//./fonts/slick.woff") format("woff"), url("..//./fonts/slick.ttf") format("truetype"), url("..//./fonts/slick.svg#slick") format("svg");
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0px;
  color: transparent;
  background: transparent;
  border: none;
  outline: none;
  transform: translate(0, -50%);
  transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
  opacity: 1;
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
  opacity: 0.25;
}

.slick-prev::before,
.slick-next::before {
  font-size: 20px;
  line-height: 1;
  font-family: "slick";
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev::before {
  content: "←";
}

[dir="rtl"] .slick-prev::before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next::before {
  content: "→";
}

[dir="rtl"] .slick-next::before {
  content: "←";
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0px;
  color: transparent;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover::before,
.slick-dots li button:focus::before {
  opacity: 1;
}

.slick-dots li button::before {
  content: "•";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  font-family: "slick";
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button::before {
  color: black;
  opacity: 0.75;
}